import React, { useEffect, useRef } from 'react';

function LegitScript() {
  useEffect(() => {
    

    const legitScript = document.querySelector('.legitscript') as HTMLElement;
    const legitScriptContainer = document.querySelector('.legitscript-container') as HTMLElement;

    if(!legitScript) return;
    
    legitScriptContainer.appendChild(legitScript);
    legitScript.classList.remove('legitscript-hidden');
    
  }, []);

  return (
    <div className='legitscript-container'></div>
  );
}


export default LegitScript;
