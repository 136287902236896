import React, { Suspense } from "react";
import "./index.scss";
import Loading from "./components/loader";
import {
  saveUser,
  getUser,
  clearStorage,
  setItem,
  getItem,
  removeItem,
} from "./services/storage";
import * as path from "./routes/constants";
import { parseToken } from "./services/helper";
import { notifError, notifSuccess } from "./routes/util";
import { CONSTANTS } from "./routes/constants";
import AppErrorBoundary from "./components/app-error-boundary";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import { pharmacistMonthlyOrder } from "services/apis";
import LegitScript from "components/legitscript";

const Routes = React.lazy(() => import("./routes"));
class RouteContainer extends React.PureComponent<
  {},
  {
    role: string | null;
    loading: boolean;
  }
> {
  state = {
    role: null,
    loading: true,
  };

  componentDidMount = async () => {
    let user = getUser();
    if (user) {
      let { role } = user;
      if (role) this.setState({ role });
    }
    this.setState({ loading: false });
  };

  onLogin = async (token: string, router: any) => {
    if (getItem("checkCalling") !== null) {
      notifSuccess("", "Successfully logged in");
      saveUser(token);
      removeItem("checkCalling");
      let url = getItem("url");
      window.location.href = url!;
    } else {
      clearStorage();
      let {
        role,
        payment = true,
        shipping,
        billing,
        login_count,
        playId,
      } = parseToken(token);
      if (role === "User" && !payment) {
        notifError("", CONSTANTS.REQ_PAYMENT);
        router.push({
          pathname: path.BILLING,
          state: { paymentPending: true, token, shipping, billing },
        });
      } else {
        notifSuccess("", "Successfully logged in");
        saveUser(token);
        let loginCount = login_count <= 1 ? true : false;
        setItem("loginCount", loginCount);
        if (role === "Pharmacist") {
          await pharmacistMonthlyOrder();
        }
        if (typeof playId === "string") setItem("playerId", playId);
        if (role) {
          this.setState({ role });
          window.location.href = "/";
        }
      }
    }
  };

  render() {
    const { role, loading } = this.state;
    const isProduction = process.env.NODE_ENV === "production";
    return loading ? (
      <>
        <CacheBuster
          currentVersion={packageInfo.version}
          isEnabled={isProduction} //If false, the library is disabled.
          isVerboseMode={false}
        >
          <Loading show={loading} />
        </CacheBuster>
      </>
    ) : (
      <>
        <CacheBuster>
          <AppErrorBoundary>
            <Suspense fallback={<Loading show={true} />}>
              <Routes userRole={role} onLogin={this.onLogin} />
            </Suspense>
          </AppErrorBoundary>
        </CacheBuster>
        <LegitScript/>
        {/* <LegitScript isLoading={loading}/>  */}
      </>
    );
  }
}

export default RouteContainer;
