// import ButtonComponent from "components/button";
import * as React from "react";
// import { Link } from "react-router-dom";
// import { LANDING_PAGE } from "routes/constants";

class ErrorHandler extends React.Component<any> {
  state = { error: null };
  componentDidCatch(error: any) {
    console.log("error", error);
    this.setState({ error });
  }
  render() {
    if (this.state.error) {
      // Render fallback UI
      return (
        <div id="app-error">
          
            {/* <div className="div-container-heading">
              <h1>Oops! I think you are lost.</h1>
              <Link to={LANDING_PAGE}>
                <ButtonComponent btnText="Go To Home" className="getStarted-btn" />
              </Link>
            </div> */}
          
          {/* <p>
            {t("app-error.sorry1")}
            <span role="img" aria-label="emoji">
              {" "}
              😳 ⚠️
            </span>
            {t("app-error.sorry2")}
          </p>
          <p>{t("app-error.reload")}</p> */}
        </div>
      );
    } else {
      return <>{this.props.children}</>;
    }
    // When there's not an error, render the children untouched
  }
}

export default ErrorHandler;
