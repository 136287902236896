import axios, {AxiosRequestConfig} from "axios";
import { getToken, clearStorage } from "../storage";
export const BASE_URL = process.env.REACT_APP_BASE_URL;

const getHeader = () => {
  const header: AxiosRequestConfig['headers'] = {
    "content-type": "application/json",
    Accept: "application/json",
    "time-zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
    "Access-Control-Allow-Origin": "*",
  };
  try {
    const token = getToken();
    if(token) header["Authorization"] = token;
  } catch (err) {
    console.log("ERROR:", err);
  }
  return header;
};

const handleError = (error: any) => {
  try {
    if (error && error.response) {
      if (error.response.status === 401) {
        clearStorage();
        window.location.href = "/";
      } else if (error.response.status === 400) {
        if (error && error.response && error.response.data) {
          const { errors, msg = "something went wrong." } = error.response.data;
          if (errors && typeof errors === "string")
            return { status: false, msg: errors };
          else if (msg && typeof msg === "string")
            return { status: false, msg };
          else if (msg && typeof msg === "object") {
            let info = msg[Object.keys(msg)[0]].toString();
            return { status: false, msg: info };
          }
        }
      } else {
        if (error && error.response && error.response.data) {
          const { errors, msg = "something went wrong." } = error.response.data;
          if (errors && typeof errors === "string")
            return { status: false, msg: errors };
          else if (msg && typeof msg === "string")
            return { status: false, msg };
        }
      }
    }
  } catch (error) {
    console.log("HANDLE ERROR:", error);
  }
  return { status: false, msg: "something went wrong." };
};
export default {
  get: (url: string) => {
    return new Promise((resolve, reject) =>
      axios
        .get(BASE_URL + url, { headers: getHeader() })
        .then((response) => resolve(response))
        .catch((error) => {
          handleError(error);
          resolve(error.response);
        })
    );
  },
  post: (url: string, data: string, access_token?: string) => {
    let requestHeaders = getHeader();
    if (access_token) requestHeaders["Authorization"] = access_token;
    return new Promise((resolve, reject) =>
      axios
        .post(BASE_URL + url, data, { headers: requestHeaders })
        .then((response) => resolve(response))
        .catch((error) => resolve(handleError(error)))
    );
  },

  put: (url: string, data: any) => {
    return new Promise((resolve, reject) =>
      axios
        .put(BASE_URL + url, data, { headers: getHeader() })
        .then((response) => resolve(response))
        .catch((error) => resolve(handleError(error)))
    );
  },
  delete: (url: string, data: string) => {
    return new Promise((resolve, reject) =>
      axios
        .delete(BASE_URL + url, { data, headers: getHeader() })
        .then((response) => resolve(response))
        .catch((error) => resolve(handleError(error)))
    );
  },

  postFile: (url: string, data: any) => {
    const formData = new FormData();
    for (const property in data) {
      formData.append(property, data[property]);
    }
    return new Promise((resolve, reject) =>
      axios
        .post(BASE_URL + url, formData, { headers: getHeader() })
        .then((res) => resolve(res))
        .catch((error) => reject(handleError(error)))
    );
  },
  putFile: (url: string, data: any) => {
    const formData = new FormData();
    for (const property in data) {
      formData.append(property, data[property]);
    }
    return new Promise((resolve, reject) =>
      axios
        .put(BASE_URL + url, formData, { headers: getHeader() })
        .then((res) => resolve(res))
        .catch((error) => reject(handleError(error)))
    );
  },
};
