import moment from "moment";
import Field from "components/Field";
import { clearStorage, getItem, getToken } from "../storage";

export const parsePlans = (plans: any) => {
  let p = [];
  for (let i = 0; i < plans.length; i++) {
    p.push({
      plan: plans[i].name,
      drugName: plans[i].drug,
      dosage: plans[i].dosage,
      frequency: plans[i].frequency,
      amount: plans[i].amount,
      id: plans[i].id,
      detail: plans[i].detail,
    });
  }
  return p;
};

export const parseQuestionnaire = (questionnaire: any) => {
  let q = [];
  for (let i = 0; i < questionnaire.length; i++) {
    var ques: any = {};
    ques["type"] = questionnaire[i].types;
    if (questionnaire[i].types !== "input")
      ques["name"] = questionnaire[i].id.toString();
    else ques["name"] = questionnaire[i].id.toString() + "-single-text";
    ques["label"] = i + 1 + ". " + questionnaire[i].question;
    ques["component"] = Field;
    ques["required"] = true;
    ques["gridLg"] = 12;
    if (
      questionnaire[i].types === "radio" ||
      questionnaire[i].types === "checkbox"
    ) {
      let op = [];
      for (let j = 0; j < questionnaire[i].options.length; j++) {
        op.push({
          value: questionnaire[i].options[j].id.toString(),
          label: questionnaire[i].options[j].option,
          showInput: questionnaire[i].options[j].input,
        });
      }
      ques["options"] = op;
      if (
        questionnaire[i].types === "radio" &&
        questionnaire[i].options[0]?.input
      )
        ques["radioInput"] = true;
    } else {
      ques["placeholder"] = questionnaire[i].question;
      if (
        questionnaire[i].question === "Height" ||
        questionnaire[i].question === "Weight"
      ) {
        ques["gridItem"] = 6;
        ques["type"] = "number";
        if (questionnaire[i].question === "Height")
          ques["placeholder"] = questionnaire[i].question + " (in centimeters)";
        else ques["placeholder"] = questionnaire[i].question + " (in kgs)";
      }
    }

    q.push(ques);
  }
  return q;
};

export const parseAppointment = (appointment: any) => {
  let date = formatDate(appointment.start);
  let time = appointment.start.substr(11, 5);
  return { date, time };
};

export const parseQuestionnaireData = (questionnaire: any) => {
  let allQues: any = [];
  for (const item in questionnaire) {
    var ques: any = {};
    if (!item.includes("input")) {
      if (Array.isArray(questionnaire[item])) {
        ques["answer_option"] = [];
        for (let i = 0; i < questionnaire[item].length; i++) {
          let id = Number(questionnaire[item][i]);
          let obj: any = {
            option_id: id,
            value: questionnaire[`${id}-input`]
              ? questionnaire[`${id}-input`]
              : "",
          };
          ques["answer_option"].push(obj);
        }
        ques["question_id"] = Number(item);
      } else {
        if (item.includes("single-text")) {
          ques["answer_option"] = [
            {
              option_id: "",
              value: questionnaire[item],
            },
          ];
          ques["question_id"] = Number(
            item.substring(0, item.indexOf("-single-text"))
          );
        } else {
          const id = Number(questionnaire[item]);
          ques["answer_option"] = [
            {
              option_id: id,
              value: questionnaire[`${id}-input`]
                ? questionnaire[`${id}-input`]
                : "",
            },
          ];
          ques["question_id"] = Number(item);
        }
      }
      allQues.push(ques);
    }
  }
  return allQues;
};


export const createProfilePayload = (data: any, checked: boolean) => {
  console.info("data=======",data.expDate)
  let personalDetail = getItem("personalDetail");
  let demographics = getItem("demographics");
  let questionnaire = getItem("questionnaire");

  let plaId = getItem("planId");
  if (!personalDetail || !demographics || !questionnaire || !data.expDate) return false;
  let ques = parseQuestionnaireData(questionnaire);
  let payload = {
    user: {
      first_name: personalDetail.firstName,
      last_name: personalDetail.lastName,
      phone: "+" + personalDetail.phone,
      email: personalDetail.email,
      password: personalDetail.password,
      dob: formatDate(demographics.dob),
      gender: demographics.gender,
      marital_status: demographics.maritalStatus,
      employment: demographics.employment,
      ethnicity: demographics.ethnicity,
    },
    questionnaire: ques,
    billing: {
      plan_id: plaId,
      add1: checked ? data.shippingAddress : data.billingAddress,
      add2: checked ? data.shippingAddress2 : data.billingAddress2,
      city: checked ? data.shippingCity : data.billingCity,
      state: checked ? data.shippingState : data.billingState,
      zip_code: checked ? data.shippingZipCode : data.billingZipCode,
    },
    shipping: {
      add1: data.shippingAddress,
      add2: data.shippingAddress2,
      city: data.shippingCity,
      state: data.shippingState,
      zip_code: data.shippingZipCode,
    },
    // payments: billing.payments,
    payment: {
      expiry_date: data.expDate,
      card_number: data.cardNumber,
      name: data.holderName,
      payment_type: "card",
    },
  };
  return payload;
};

export const formatDate = (inputDate: any, format?: any) => {
  var date = new Date(inputDate);
  let month = getDayOrMonth(date, "month");
  let day = getDayOrMonth(date, "day");
  let year = date.getFullYear();
  switch (format) {
    case "MM/DD/YYYY":
      return month + "/" + day + "/" + year;
    default:
      return month + "/" + day + "/" + year;
  }
};

export const getDayOrMonth = (date: Date, type: string) => {
  let d;
  if (type === "month") d = date.getMonth() + 1;
  else d = date.getDate();
  if (d.toString().length === 1) return "0" + d;
  return d;
};

export const parseToken = (token: any) => {
  try {
    let access_token = token.access_token;
    var payload = JSON.parse(window.atob(access_token.split(".")[1]));
    return payload;
  } catch (error) {
    clearStorage();
    return null;
  }
};

export const parseViewProfile = (profile: any) => {
  return {
    "first name": profile.first_name,
    "last name": profile.last_name,
    email: profile.email,
    dob: moment(new Date(profile.dob)).format("MM-DD-YYYY"),
    "shipping Address": profile.address?.add1,
    "shipping Address2": profile.address?.add2,
    "shipping City": profile.address?.city,
    "shipping State": profile.address?.state,
    "shipping ZipCode": profile.address?.zip_code,
    gender: profile.gender,
    "marital Status": profile.marital_status,
    ethnicity: profile.ethnicity,
    employment: profile.employment,
  };
};

export const parseRoom_TokenValue = (payload: any) => {
  let obj: any = {};
  obj["room"] = payload.room;
  obj["token"] = payload.token;
  return obj;
};

export const isLoggedIn = () => {
  let token = getToken();
  if (token != null) return true;
  else return false;
};

export const parsePatientNotification = (payload: any) => {
  return (
    payload?.map((item: any) => ({
      notification: `${item.des} `||` You have ${item?.name}`,
      // notification: item.des || "You have" + " " + item?.name,
      url: item["name"] === "upcoming_order" ? "/my-order" : "/medical-docs",
    })) || []
  );
};
export const parseNurseNotification = (payload: any) => {
  return (
    payload?.map((item: any) => ({
      notification: `${item.des}` || `You have ${item?.name}`,
      // notification: item.des || "You have" + " " + item?.name,
      url: item["name"] === "followup" ? "/follow-up" : "/new-requests",
    })) || []
  );
};
export const parsePharmacistNotification = (payload: any) => {
  return (
    payload?.map((item: any) => ({
      notification: `You have ${item?.name}` || `${item.des}`,
      // notification: "You have" + " " + item?.name || item.des,
      url: item["name"] === "upcoming_order" ? "/" : "",
    })) || []
  );
};

export const parsePhysicianNotification = (payload: any) => {
  return (
    payload?.map((item: any) => ({
      notification: `You have ${item?.name} || ${item.des}`,
      // notification: "You have" + " " + item?.name || item.des,
      url: item["name"] === "report" ? "/" : "",
    })) || []
  );
};

export const DB_FieldName = (name: string) => {
  if (name === "First Name") return "first_name";
  else if (name === "Date Of Birth") return "dob";
  else if (name === "Last Name") return "last_name";
  else if (name === "Date") return "date";
  else if (name === "Status") return "order_status";
  else if (name === "Email") return "email";
  else if (name === "Address") return "state";
  else if (name === "All Field") return "";
};