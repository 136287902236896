import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { HelmetProvider } from "react-helmet-async";
ReactDOM.render(
  <>
    <ReactNotifications />
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </>,
  document.getElementById("root")
);

serviceWorker.register();
