import { Store as notify } from "react-notifications-component";
import { saveUser, clearStorage, setItem } from "../services/storage";
import axios from "axios";
import { BASE_URL } from "../services/apis/request";


export const resetRouter = () => {
  window.location.href = "/";
};

export const notifError = (title: string, message?: string) => {
  if (message === null && message === undefined) {
    message = " ";
  }
  notify.addNotification({
    title,
    message,
    type: "warning", //"danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

export const notifSuccess = (title: string, message?: string) => {
  if (message === null && message === undefined) {
    message = " ";
  }
  notify.addNotification({
    title,
    message,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

export const notifInfo = (title: string, message?: string) => {
  notify.addNotification({
    title,
    message,
    type: "info",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

export const notifWarning = (title: string, message?: string) => {
  notify.addNotification({
    title,
    message,
    type: "warning",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

export const PASWORD_MATCHER =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const verifyPassword = (value: string): string => {
  if (value.length === 0) return "Password field is required";
  else if (!/[a-z]/.test(value)) return "Atleast 1 Lowercase letter";
  else if (!/[A-Z]/.test(value)) return "Atleast 1 Uppercase letter";
  else if (!/[0-9]/.test(value)) return "Atleast 1 Numeric character";
  // eslint-disable-next-line
  else if (!/[!@#\$%\^&\*]/.test(value)) return "Atleast 1 Special character";
  else if (value.length < 8) return "Minimum length must be 8 characters";

  return "";
};

export const verifyState = (value: string): string => {
  if (value === "North Dakota" || value === "South Carolina")
    return "We are currently serving in New York and Florida and will keep you informed as soon as we have availability"
    // return "Sorry, we do not deliver at your location!";
  else return "";
};
export const removeBlankInput = (values: any) => {

  let keys = Object.keys(values);
  let payload: any = {};
  keys.map((key) => {
    if (!key.includes("-input")) {
      payload[key] = values[key];
    } else if (key.includes("-input") && values[key] !== "") {
      payload[`${key}`] = values[key];
    }
  });
  return payload;
};

const preZero = (n: number): any => (n > 9 ? n : "0" + n);

export const formatDate = (d: Date): string => {
  if (d) {
    return `${preZero(d.getMonth() + 1)}/${preZero(
      d.getDate()
    )}/${d.getFullYear()}`;
  } else return "";
};

export const formatDateTime = (d: Date): string => {
  if (d != null) {
    return `${preZero(d.getMonth() + 1)}/${preZero(
      d.getDate()
    )}/${d.getFullYear()} ${preZero(d.getHours())}:${preZero(d.getMinutes())}`;
  } else return "";
};

type LoginType = {
  email: string;
  password: string;
  payment: boolean;
};
type payload = {
  payload: LoginType;
  history: any;
}

export const moveDocumentSection = async ({ payload, history }: payload) => {
  if (payload) {
    loginApi(payload, history);
    clearStorage();
  } else window.location.href = "/";
};

const loginApi = (payload: any, history: any) => {
  let url = BASE_URL + "api/v1/login";
  new Promise((resolve, reject) =>
    axios
      .post(url, payload)
      .then((response) => {
        saveUser(response.data.tokens);
        setItem("isVisible", true)
        window.location.href = "/medical-docs";
        setItem("uploadDocumentMsg", true);
      })
      .catch((error) => reject(false))
  );
};

export const eighteenYearsAgo = () => {
  let d = new Date();
  d.setFullYear(d.getFullYear() - 18);

  return d;
};

export interface IplanDetail {
  amount: string;
  detail: object;
}

export const dosageList = (plandetail: IplanDetail[]) => {
  let dosage: string[] = [];
  for (let i = 0; i < plandetail.length; i++) {
    const { detail } = plandetail[i];
    Object.keys(detail).map((value, index: number) => {
      dosage.push(value);
    });
  }
  let filter = Array.from(new Set(dosage));
  return filter;
};

const SildenafilColors = ["linear-gradient(180deg, #C97AF3 0%, #B056E1 90%)", "linear-gradient(180deg, #B056E1 0%,  #7600B5 90%)", "linear-gradient(180deg, #7600B5 0%, #52017E 90%)"]
const  TadalafilColors= ["linear-gradient(180deg, #B0E9E8 0%,  #67C7C6 90%)", "linear-gradient(180deg, #4EC7DE 0%,  #108CB2 90%)", "linear-gradient(180deg, #0290AB 0%,  #004967 90%)"]

export const handlePlanCardBgColor = (plan: string, index: number) => {
  let color = plan === "Sildenafil" ? SildenafilColors : TadalafilColors
  switch (index) {
    case 0:
      return color[0];
    case 1:
      return color[1];
    case 2:
      return color[2];
    default:
      return color[0];
  }
}
const sildenafilBorder=["#B056E1","#7600B5","#52017E"]
const  tadalafilBorder=["#67C7C6"," #108CB2","#004967"]

export const handleSelectPlanBorder=(plan: string, index: number) => {
  let color = plan === "Sildenafil" ? sildenafilBorder : tadalafilBorder
  switch (index) {
    case 0:
      return color[0];
    case 1:
      return color[1];
    case 2:
      return color[2];
    default:
      return color[0];
  }
}

const colorCode = ["#e151cb", "#00e4ca", "#854DB7"];//#E3A1FF
export const handlePlanBackgroundColor = (planSelect: any) => {
  switch (planSelect) {
    case 'Sildenafil':
      return colorCode[0];
    case 'Tadalafil':
      return colorCode[1];
    default:
      return colorCode[0];
  }
}