import { Response, login_type, reset_pswd_type,remarks_type } from "./types";
import { notifError } from "../../routes/util";
import Request from "./request";

const Apis = {
  GET_TREATMENT: "api/v1/treatments",
  GET_PLANS: "api/v1/plans/",
  GET_PLANS_Grid: "api/v1/plan/grid/",
  GET_QUETIONNAIRE: "api/v1/questionnaire/",
  CHECK_EMAIL: "api/v1/checkuser/",
  CREATE_PROFILE: "api/v1/signup",
  LOGIN: "api/v1/login",
  FORGOT_PASSWORD: "api/v1/forgot/",
  RESET_PASSWORD: "api/v1/resetpassword",
  GET_PROFILE: "api/v1/profile",
  GET_BILLING: "api/v1/billing",
  UPDATE_CARD: "api/v1/update/card",
  UPDATE_PROFILE: "api/v1/profile",
  UPDATE_BILLING: "api/v1/billing",
  UPDATE_PAYMENT: "api/v1/",
  UPDATE_QUESTIONNAIRE: "api/patient/v1/questionnaire",
  UPLOAD_FILE: "api/patient/v1/document",
  UPLOAD_ID_PROOF: "api/v1/id/proof",
  SET_APPOINTMENT: "api/v1/appointment/set",
  PATIENT_REQUEST: "api/nurse/v1/patient/",
  SET_ROOM_TOKEN: "api/v1/appointment/call/video",
  CLEARANCE_ID: "api/patient/v1/cl/idproof/info/",
  GET_PENDING_CLEARANCE: "api/nurse/v1/changeclearancestatus",
  UPDATE_CLEARANCE: "api/nurse/v1/changeclearancestatus/",
  RESCHEDULE_APPOINTMENT: "api/v1/appointment/reschedule",
  PATIENT_REPORTS: "api/v1/appointment/patient/report/",
  PATIENT_NURSE_REPORTS: "api/v1/appointment/report/",
  PATIENT_DETAIL: "api/patient/v1/medical/info/",
  NURSE_ACCEPT_REQUEST: "api/v1/appointment/accept/",
  FOLLOW_UP: "api/v1/appointment/followUp/",
  NURSE_NOTIFICATION: "api/nurse/v1/nurse/notify",
  PLAN_DETAIL: "api/patient/v1/user/plan",
  ORDERS_HISTORY: "api/patient/v1/order/history",
  CANCEL_VACATION: "api/patient/v1/cancel/vacation",
  PATIENT_NOTIFICATION: "api/patient/v1/notify",
  PLAN_STATUS: "api/patient/v1/planstatus/",
  PYSICIAN_PATIENTLIST: "api/physician/v1/patient/list",
  CHANGE_PASSWORD: "api/v1/changepassword",
  UPDATE_PLAYER_ID: "api/v1/appointment/player",
  PHYSICIAN_REVIEW: "api/physician/v1/physician/patient/reportremark/",
  PHYSICIAN_PATIENT_CHART: "api/physician/v1/patientchart",
  PHYSICIAN_NOTIFICATION: "api/physician/v1/notification",
  PHARMACIST_NEW_ORDER: "api/pharmacist/v1/new/order",
  PHARMACIST_ORDER_HISTORY: "api/pharmacist/v1/order/history",
  PHARMACIST_NOTIFICATION: "api/pharmacist/v1/notify",
  PHARMACIST_UPDATE_ORDER_STATUS: "api/pharmacist/v1/order/status/",
  PHARMACIST_MONTHLY_ORDER: "api/pharmacist/v1/month/order",
  END_VIDEO_CALL: "api/v1/appointment/end-video-call/",
  SIGNOFF_BULK: "api/physician/v1/signoff/bulk ",
  CLEARANCE_LETTER: "api/v1/clearance/form",
  MAKE_PAYMENT: "api/pharmacist/v1/make/payment/status/",
  TRACKING_DETAILS: "api/patient/v1/track/order/",
  SIGNOFF_BULK_NURSE: "api/nurse/v1/accept/bulk ",
  UPDATE_REMARKS:"api/patient/v1/remarks",
};

const getError = (msg: any) => {
  let m = "Something went wrong";
  try {
    if (typeof msg === "object") {
      Object.entries(msg).map(([key, value]: any) => {
        if (typeof value === "object" && value && value.length)
          m = `${key}: ${value[0]}`;
        else m = `${key}: ${value}`;
      });
    } else if (typeof msg === "string") m = msg;
  } catch (error) {
    console.log("ERROR", error);
  }
  return m;
};

const verifyResponse = (resp: Response) => {
  try {
    if (resp.data && !resp.data.status) {
      let message;
      if (resp.data?.msg.msg) message = resp.data.msg.msg[0];
      else message = getError(resp.data.msg);
      if (message && typeof message === "string")
        notifError("", message || "Something went wrong");
      else notifError("", "Something went wrong");
    }
  } catch (err) {
    console.log("ERROR: ", err);
  }
  return resp?.data || {};
};

export const getTreatments = async () => {
  let treatments: any = await Request.get(Apis.GET_TREATMENT);
  return verifyResponse(treatments);
};
export const endVideoCall = async (id: number) => {
  const resp: any = await Request.post(Apis.END_VIDEO_CALL + id, "{}");
  return verifyResponse(resp);
};
export const getPlans = async (id: string) => {
  let plans: any = await Request.get(Apis.GET_PLANS + id);
  return verifyResponse(plans);
};
export const GetPlanGrid = async (id: number) => {
  let plans: any = await Request.get(Apis.GET_PLANS_Grid + id);
  return verifyResponse(plans);
};
export const getTrackingDetails = async (id: number) => {
  let plans: any = await Request.get(Apis.TRACKING_DETAILS + id);
  return verifyResponse(plans);
};
export const fetchSinglePatient = async (id: number) => {
  let plans: any = await Request.get(Apis.PATIENT_DETAIL + id);
  return verifyResponse(plans);
};
export const getQuestionnaire = async (id: string) => {
  let questionnaire: any = await Request.get(Apis.GET_QUETIONNAIRE + id);
  return verifyResponse(questionnaire);
};
export const verifyEmailPhone = async (phone: string, email: string) => {
  let resp: any = await Request.get(`${Apis.CHECK_EMAIL}${email}/+${phone}`);
  return verifyResponse(resp);
};
export const createProfile = async (payload: any) => {
  let resp: any = await Request.post(
    Apis.CREATE_PROFILE,
    JSON.stringify(payload)
  );
  return verifyResponse(resp);
};
export const login = async (payload: login_type) => {
  let resp: any = await Request.post(Apis.LOGIN, JSON.stringify(payload));
  return verifyResponse(resp);
};
export const forgotPassword = async (email: string) => {
  let resp: any = await Request.get(Apis.FORGOT_PASSWORD + email);
  return verifyResponse(resp);
};
export const resetPassword = async (payload: reset_pswd_type) => {
  let resp: any = await Request.post(
    Apis.RESET_PASSWORD,
    JSON.stringify(payload)
  );
  return verifyResponse(resp);
};
export const getProfile = async () => {
  let resp: any = await Request.get(Apis.GET_PROFILE);
  return verifyResponse(resp);
};
export const getBillingDetails = async () => {
  let resp: any = await Request.get(Apis.GET_BILLING);
  return verifyResponse(resp);
};
export const updateCardDetails = async (payload: any) => {
  let resp: any = await Request.put(Apis.UPDATE_CARD, JSON.stringify(payload));
  return verifyResponse(resp);
};
export const editProfile = async (payload: any) => {
  let resp: any = await Request.put(
    Apis.UPDATE_PROFILE,
    JSON.stringify(payload)
  );
  return verifyResponse(resp);
};
export const editBilling = async (payload: any) => {
  let resp: any = await Request.put(
    Apis.UPDATE_BILLING,
    JSON.stringify(payload)
  );
  return verifyResponse(resp);
};
export const editPaymentInfo = async (payload: any) => {
  // let resp: any = await Request.put(Apis.UPDATE_PAYMENT,JSON.stringify(payload));
  // return verifyResponse(resp);
};
export const getQuestionnaireForUpdate = async () => {
  let resp: any = await Request.get(Apis.UPDATE_QUESTIONNAIRE);
  return verifyResponse(resp);
};
export const uploadFileDetail = async (payload: any) => {
  let resp: any = await Request.postFile(Apis.UPLOAD_FILE, payload);
  return verifyResponse(resp);
};
export const uploadIDProof = async (payload: any) => {
  let resp: any = await Request.postFile(Apis.UPLOAD_ID_PROOF, payload);
  return verifyResponse(resp);
};
export const getFileDetails = async (page: string) => {
  let resp: any = await Request.get(Apis.UPLOAD_FILE + page);
  return verifyResponse(resp);
};
export const getIdProofDetails = async (page: string) => {
  let resp: any = await Request.get(Apis.UPLOAD_ID_PROOF + page);
  return verifyResponse(resp);
};
export const getUserRequestList = async (status: string, page = "") => {
  let resp: any = await Request.get(Apis.PATIENT_REQUEST + status);
  return verifyResponse(resp);
};
export const fetchViewClearnceId = async (id: number) => {
  let resp: any = await Request.get(Apis.CLEARANCE_ID + id);
  return verifyResponse(resp);
};
export const getClearanceReq = async (page = "") => {
  let resp: any = await Request.get(Apis.GET_PENDING_CLEARANCE + page);
  return verifyResponse(resp);
};
export const updateClearanceReq = async (
  userId: string,
  data: {
    clearance_status: "Approved" | "Rejected";
    description?: string;
  }
) => {
  let resp: any = await Request.putFile(Apis.UPDATE_CLEARANCE + userId, data);
  return verifyResponse(resp);
};
export const getVideoToken_RoomName = async (payload: any) => {
  let resp: any = await Request.post(
    Apis.SET_ROOM_TOKEN,
    JSON.stringify(payload)
  );
  return verifyResponse(resp);
};
export const setAppointmentByNurse = async (payload: any, status?: string) => {
  payload["minutes"] = 15;
  let resp: any = await Request.put(
    Apis.SET_APPOINTMENT + "/" + payload.id,
    JSON.stringify(payload)
  );
  return verifyResponse(resp);
};
export const rescheduleAppointment = async (requestId: any) => {
  let resp: any = await Request.put(
    Apis.RESCHEDULE_APPOINTMENT + "/" + requestId,
    "{}"
  );
  return verifyResponse(resp);
};
export const getPatientReport = async (
  patient_id: number,
  appointment_id: number
) => {
  let patientReportDetails: any = await Request.get(
    Apis.PATIENT_REPORTS + patient_id + "/" + appointment_id
  );
  return verifyResponse(patientReportDetails);
};
export const updatePatientReport = async (
  id: number,
  data: {
    patient_id: string;
    appointment_id: string;
    detail?: string;
  }
) => {
  let patientReportDetails: any = await Request.put(
    Apis.PATIENT_NURSE_REPORTS + id,
    data
  );
  return verifyResponse(patientReportDetails);
};
export const acceptAppointmentByNurse = async (
  userId: string,
  data: {
    clearance_status: "Approved" | "Rejected";
    description?: any;
  }
) => {
  let resp: any = await Request.put(Apis.NURSE_ACCEPT_REQUEST + userId, data);

  return verifyResponse(resp);
};
export const setFollowUp = async (id: number) => {
  let resp: any = await Request.put(Apis.FOLLOW_UP + id, JSON.stringify({}));
  return verifyResponse(resp);
};
export const patientCurrentPlanDetail = async () => {
  let planDetail: any = await Request.get(Apis.PLAN_DETAIL);
  return verifyResponse(planDetail);
};
export const getNurseNotificationData = async () => {
  let notificationList: any = await Request.get(Apis.NURSE_NOTIFICATION);
  return verifyResponse(notificationList);
};
export const getPatientOrderHistory = async (page?: string) => {
  let orderHistory: any = await Request.get(Apis.ORDERS_HISTORY + page);
  return verifyResponse(orderHistory);
};
export const cancelPatientVacation = async () => {
  let resp: any = await Request.post(Apis.CANCEL_VACATION,"");
  return verifyResponse(resp);
};
export const getPatientNotification = async () => {
  let notificationList: any = await Request.get(Apis.PATIENT_NOTIFICATION);
  return verifyResponse(notificationList);
};
export const upgradePatientPlan = async (payload: any, status: string) => {
  let resp: any = await Request.put(
    Apis.PLAN_STATUS + status,
    JSON.stringify(payload)
  );

  return verifyResponse(resp);
};
export const getPhysicianPatientList = async (page?: string) => {
  let resp: any = await Request.get(Apis.PYSICIAN_PATIENTLIST + page);
  return verifyResponse(resp);
};
export const changePassword = async (payload: any) => {
  let resp: any = await Request.put(
    Apis.CHANGE_PASSWORD,
    JSON.stringify(payload)
  );
  return verifyResponse(resp);
};
export const updatePlayerId = async (payload: any) => {
  let resp: any = await Request.put(
    Apis.UPDATE_PLAYER_ID,
    JSON.stringify(payload)
  );
  return verifyResponse(resp);
};
export const makePayment = async (payload: any, id: number) => {
  let resp: any = await Request.post(
    Apis.MAKE_PAYMENT + id,
    JSON.stringify(payload)
  );
  return verifyResponse(resp);
};
export const pharmacistMonthlyOrder = async () => {
  let resp: any = await Request.post(Apis.PHARMACIST_MONTHLY_ORDER,"");
  return verifyResponse(resp);
};
export const sendPhysicianReview = async (payload: any, reportId: string) => {
  let resp: any = await Request.post(
    Apis.PHYSICIAN_REVIEW + reportId,
    JSON.stringify(payload)
  );
  return verifyResponse(resp);
};
export const getPhysician_PatientChart = async (page: string) => {
  let resp: any = await Request.get(Apis.PHYSICIAN_PATIENT_CHART + page);
  return verifyResponse(resp);
};
export const physician_notification = async () => {
  let resp: any = await Request.get(Apis.PHYSICIAN_NOTIFICATION);
  return verifyResponse(resp);
};
export const getPhysicianReview = async (patientId: number, app_id: number) => {
  let resp: any = await Request.get(
    Apis.PHYSICIAN_REVIEW + patientId + "/" + app_id
  );
  return verifyResponse(resp);
};
export const getPharmacist_NewOrderList = async (page: any) => {
  let resp: any = await Request.get(Apis.PHARMACIST_NEW_ORDER + page);
  return verifyResponse(resp);
};

export const getPharmacist_OrderHistory = async (page: any) => {
  let resp: any = await Request.get(Apis.PHARMACIST_ORDER_HISTORY + page);
  return verifyResponse(resp);
};
export const getPharmacist_Notification = async () => {
  let resp: any = await Request.get(Apis.PHARMACIST_NOTIFICATION);
  return verifyResponse(resp);
};
export const updatePharmacist_OrderStatus = async (
  payload: any,
  OrderId: number
) => {
  let resp: any = await Request.put(
    Apis.PHARMACIST_UPDATE_ORDER_STATUS + OrderId,
    JSON.stringify(payload)
  );
  return verifyResponse(resp);
};
export const signOffBulk = async (payload: any) => {
  let resp: any = await Request.post(
    Apis.SIGNOFF_BULK,
    JSON.stringify(payload)
  );
  return verifyResponse(resp);
};
export const signOffBulkNurse = async (payload: any) => {
  let resp: any = await Request.post(
    Apis.SIGNOFF_BULK_NURSE,
    JSON.stringify(payload)
  );
  return verifyResponse(resp);
};
export const uploadClearanceLetterAPI = async (data: any) => {
  let resp: any = await Request.postFile(Apis.CLEARANCE_LETTER, data);
  return verifyResponse(resp);
};
export const getClearanceLetterAPI = async () => {
  let resp: any = await Request.get(Apis.CLEARANCE_LETTER);
  return verifyResponse(resp);
};
export const submitRemarks = async (remarks:remarks_type) => {
  let resp: any = await Request.put(Apis.UPDATE_REMARKS,remarks);
  return verifyResponse(resp);
};