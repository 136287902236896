export const APP = "/",
  LOGIN = "/login",
  FORGOT_PASSWORD = "/forgot-password",
  PASSWORD_RECOVERY = "/password-recovery",
  EMAIL_VERIFICATION = "/email-verification",
  CHOOSE_TREATMENT = "/",
  CHOOSE_PLAN = "/choose-plan",
  CREATE_PROFILE = "/create-profile",
  DEMOGRAPHICS = "/demographics",
  QUESTIONNAIRE = "/questionnaire",
  BILLING = "/billings",
  CHART = "/chart",
  Home = "/home",
  PATIENT_VIDEOCALLING = "/video-call",
  ABOUT_US = "/about-us",
  CONTACT_US = "/contact-us",
  PRIVACYPOLICY = "/privacy-policy",
  HELPSUPPORT = "/help-support",
  TERMSCONDITIONS = "/terms-conditons",
  REFUNDRETURNPOLICY = "/refund-return-policy",
  DELIVERYPOLICY = "/delivery-policy",
  SAFETYINFORMATION = "/safety-information",
  COMINGSOON = "/coming-soon",
  LEARN_MORE = "/more-details",
  EMPLOYEELOGIN = "/employeelogin",
  OOPS_404 = "/oops404",
  LANDING_PAGE = "/";

export const PLANSTATUS_OPTIONS = {
  ONGOING: 'ongoing',
  SET_VACATION: 'set_vacation',
  CANCEL: 'cancel',
};

export const CONSTANTS = {
  PROFILE_SUCCESS_PAYMENT: "You have successfully signup",
  PROFILE_SUCCESS:
    "Your profile has been created successfully but payment failed.Please try again after login",
  TREATMENT_HEADER: "Back to Home Page",
  TREATMENT_TITLE: "Choose Treatment",
  TREATMENT_SUBTITLE:
    "Choose a treatment that you would like to be treated for you can add or remove treatments later.",
  PLAN_HEADER: "Back to treatments",
  PLAN_TITLE: "Pick the best plan for you",
  PLAN_SUBTITLE:
    "Choose a plan that is right for you, you can always change the later.",
  PROFILE_HEADER: "Back to plans",
  PROFILE_TITLE: "Step 1: Create Profile",
  PROFILE_SUBTITLE: "Create a profile",
  DEMOGRAPHICS_HEADER: "Back to Step 1",
  DEMOGRAPHICS_TITLE: "Step 2: Demographics",
  DEMOGRAPHICS_SUBTITLE: "Fill out demographic information",
  QUESTIONNAIRE_HEADER: "Back to Step 2",
  QUESTIONNAIRE_TITLE: "Step 3: Medical Questionnaire",
  SUB_QUESTIONNAIRE_TITLE: "Questionnaire",

  QUESTIONNAIRE_SUBTITLE:
    "Just some medical questions regarding your prescription request",
  BILLING_HEADER: "Back to Step 3",
  BILLING_TITLE: "Step 4: Billing",
  BILLING_SUBTITLE:
    "Don’t worry, we won’t charge you until the prescription has been approved by our medical team and on its way",
  FORGOT_PSWD_TITLE: "Email Sent !",
  FORGOT_PSWD_SUBTITLE:
    "Please check your inbox, we sent the code on your register email.",
  THANK_YOU: "Thank you!",
  CHART_HEADER: "My Chart",
  CURRENT_MED: "Current Medications",
  EDIT_PROFILE: "Edit Profile",
  EDIT_BILLING_HEADER: "Billing",
  EDIT_BILLING: "Edit Billing Infomation",
  ORDER_HEADER: "Orders",
  UPCOMING_ORD: "Upcoming Order",
  ORD_HIST: "Order History",
  MED_INFO_HEADER: "Update Medical Information",
  CLEARANCE_FORM_HEADER: "Clearance Letter",
  ID_PROOF: "Id Proof",

  MED_INFO_TITLE: "Medical Questionnaire",
  REQ_CALL_HEADER: "Request a Call",
  UPLOAD_DOC: "Upload Documents",
  UPLOAD_ID_PROOF: "Upload Id Proof",

  UPLOAD_FILE: "Upload a File",
  UPLOAD_FILE_ID_PROOF: "Id Proof",

  ADD_CARD_DETAILS: "Card details",
  MSG_ADD_PAYMENT: "Kindly add the card details",
  ORDER_TABLE_TITLE: "Order History",
  UPLOAD_TABLE_TITLE: "Uploaded Files",
  CHARTS_MODAL_SUBTITLE: "We have saved your information",
  REQ_PAYMENT: "Kindly complete your payment first",
  BILLING_MODAL_SUBTITLE: "We have saved your information",
  Medical_MODAL_SUBTITLE: "We have saved your information",
  FILE_UPLOAD: "Your document has been stored successfully",
  SET_APPOINTMENT: "Your appointment has been made!",
  APPOINTMENT_MSG:
    "we will text and email you to remind you for your appointment",
  REJECT_APPOINTMENT: "Sorry your appointment has not been set",
  SEARCH_PATIENT: "Search Patient",
  EVALUATIONS: "Video Calls",
  NEW_REQUESTS: "New Requests",
  CLEARANCE_REQUESTS: "New Clearance Requests",
  //PATIENT_CHART: "Patient Chart",
  HISTORY: "History",
  ACCEPTED_REQUESTS: "Triaged",
  EVALUATION_TABLEHEADING: "Today's Video Calls",
  SEARCH_TABLE_TITLE: "Results",
  CHART_TABLEHEADING: "Order History",
  PATIENT_REPORTS: "Completed Calls",
  CHART_PAGE_HEADING: "Name(Age)",
  SIGN_CHARTS: "Sign Charts",
  SIGN_CHARTS_TABLE_TITLE: "Charts",
  //RECORDS_TABLEHEADING:"All Reports",
  FOLLOW_UP: "Follow Up",
  MY_PROFILE: "Profile",
  VIEW_PROFILE: "View Profile",
  CHANGE_PSWD_TITLE: "Change Password",
  CHANGE_PSWD_SUBTITLE: "Password update successfully",
  MY_PLAN: "My Plan",
  PATIENT_CHART: "Patient Chart",
  PHARMACIST_NEW_ORDER: "New Order List",
  PHARMACIST_OLD_ORDER: "Old Order List",
  PATIENT_INFO: "Patient Information",
  PLAN_DETAIL: "Plan Information",
  ORDER_HISTORY: "Order History",
  CONFIRMATION: "Confirmation",
  PLAN_CANCEL_CONFIRMATION:
    "Are you sure you wants to Cancel the plan or Set a 'Vacation Period' during which plan will be suspended",
  NO_DOCUMENTS: "No Documents",
  NO_REMARKS: "No Remarks is set",
  NO_MEDICATION: "No Medications",
  REMARKS_HEADING: "Remarks",
  DOCUMENTATION_HEADING: "Documention Information",
  ID_PROOF_HEADING: "Id Proof",
  PATIENT_DETAIL_HEADING: "Patient Information",
  REMARKS_MAIN_HEADING: "Remark",
  MEDICAL_HEADING: "Medical Information",
  PATIENT_UPDATION_CONFIRMATION:
    "Are You wants to update the medical history or any document",
  WELCOME_MSG: "Welcome",
  CURRENTLY_NO_PLAN: "Currently you have no new Plan",
  PLAN_ERROR: "You don't have any plan",
  SET_VACATION_HEADING: "Set Vacation",
  SELECT_MONTH: "Select No of Months",
  ORDER_DELIVERY_MESSAGE: "Your Next order delivery is schedule for",
  SELECT_MONTH_MESSAGE: "Please select months for vacation period",
  DOCUMENT_UPLOAD_MESSAGE:
    "Please submit document if you have any for appointment",
  DOCUMENT_UPLOAD_MESSAGE_HEADING: "Upload Document",
  ORDER_STATUS_HEADING: "Set Order Status",
  NURSE_REMARKS: "Nurse Remarks",
  ADD_REMARKS: "Add Remarks",
  REVIEW_SUBMIT_ERROR: "Please fill review column",
  FORM_SUBMIT_ERROR: "Kindly complete all the forms",
  FILL_BILLING_INFO: "Please first fill all billing Information",
  END_CALL_CONFIRMATION:
    "Complete the appointment,if the call was successfully completed.or reschedule the appointment",
  CALL_CONFIRMATION_HEADING: "Confirm Appointment",
  SIGN_UP_LIST: "New Patients",
  MAX_DATE_MSG: "***Maximum Valid Date Range is",
  VALID_DATE_MSG: "Please enter a valid date or within maximum date range",
};
