import { parseToken } from "../helper";

export const clear = () => {
    window.onbeforeunload = function () {
        localStorage.clear();
        //return '';
    }
}

export const PLAN_ID = 'planId'

export const setItem = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value))
}

export const setPlanId = (value: any) => {
    localStorage.setItem(PLAN_ID, JSON.stringify(value))
}

export const getPlanId = (key: any) => {
    let value = localStorage.getPlanId(key)
    if (value) return JSON.parse(value)
    return null
}

export const getItem = (key: string) => {
    let value = localStorage.getItem(key)
    if (value) return JSON.parse(value)
    return null
}

export const removeItem = (key: string) => {
    localStorage.removeItem(key)
}

export const clearStorage = () => {
    localStorage.clear()
}

export const saveUser = (jwtToken: string) => {
    setItem("token", jwtToken)
}

export const getUser = () => {
    let token = getItem("token")
    if (token) return parseToken(token)
    else return null
}

export const getToken = () => {
    let token = getItem("token")
    if (token) return token.access_token
    else return null
}

export const PLAN_NAME = 'planName';

export const setPlanName = (value: any) => {
    localStorage.setItem(PLAN_NAME, JSON.stringify(value));
}

export const getPlanName = () => {
    const value = localStorage.getItem(PLAN_NAME);
    return value ? JSON.parse(value) : null;
}
